<template>
  <c-menu :close-on-select="true">
    <c-menu-button
      border-radius="40px"
      background-color="superLightGray.900"
      pl="20px"
      pr="30px"
      py="10px"
      h="40px"
      color="gray.900"
      font-size="14px"
      line-height="21px"
      font-family="Roboto"
      font-weight="normal"
      :display="['none', 'flex']"
    >
      <c-image
        :src="require('@/assets/ic-filter.svg')"
        alt="icon"
        display="inline-block"
        margin-right="5px"
      />
      Filter
    </c-menu-button>
    <c-menu-list
      z-index="10"
      min-width="240px"
    >
      <c-menu-option-group
        default-value="Semua"
        type="radio"
        @change="$emit('change', $event)"
      >
        <c-menu-item-option
          v-for="item in filters"
          :key="item.value"
          :value="item.value"
          border-bottom="1px solid #F2F2F2"
          font-size="18px"
          line-height="27px"
          py="16px"
          px="20px"
        >
          {{ item.label }}
        </c-menu-item-option>
      </c-menu-option-group>
    </c-menu-list>
  </c-menu>
</template>

<script>
export default {
  name: 'ManagementClientFilterBtn',
  props: ['filters'],
}
</script>
