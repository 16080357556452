var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-menu', {
    attrs: {
      "close-on-select": true
    }
  }, [_c('c-menu-button', {
    attrs: {
      "border-radius": "40px",
      "background-color": "superLightGray.900",
      "pl": "20px",
      "pr": "30px",
      "py": "10px",
      "h": "40px",
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px",
      "font-family": "Roboto",
      "font-weight": "normal",
      "display": ['none', 'flex']
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-filter.svg'),
      "alt": "icon",
      "display": "inline-block",
      "margin-right": "5px"
    }
  }), _vm._v(" Filter ")], 1), _c('c-menu-list', {
    attrs: {
      "z-index": "10",
      "min-width": "240px"
    }
  }, [_c('c-menu-option-group', {
    attrs: {
      "default-value": "Semua",
      "type": "radio"
    },
    on: {
      "change": function change($event) {
        return _vm.$emit('change', $event);
      }
    }
  }, _vm._l(_vm.filters, function (item) {
    return _c('c-menu-item-option', {
      key: item.value,
      attrs: {
        "value": item.value,
        "border-bottom": "1px solid #F2F2F2",
        "font-size": "18px",
        "line-height": "27px",
        "py": "16px",
        "px": "20px"
      }
    }, [_vm._v(" " + _vm._s(item.label) + " ")]);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }