var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ManagementClientsIndex', {
    attrs: {
      "clients": _vm.clients,
      "page": _vm.page,
      "export-url": _vm.exportUrl,
      "filters": _vm.statuses,
      "role": _vm.authRole,
      "title": 'Management Client',
      "total-data": _vm.totalData
    },
    on: {
      "detail": function detail($event) {
        return _vm.$router.push({
          name: 'admin.clients-detail',
          params: {
            clientId: $event
          }
        });
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }