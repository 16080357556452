<template>
  <c-flex
    flex-dir="column"
    w="100%"
  >
    <c-text>Manajemen Klien</c-text>
    <hr
      v-chakra
      my="10px"
    >

    <c-flex align-items="center">
      <c-text
        flex="1"
        font-size="2xl"
      >
        Manajemen Klien
      </c-text>
      <c-button
        v-if="role === 'super_admin'"
        h="auto"
        py="12px"
        px="28px"
        color="#927102"
        border-radius="24px"
        border-color="transparent"
        bg="secondary.800"
        variant-color="inherit"
        font-size="16px"
        line-height="24px"
        variant="solid"
        @click="$emit('add-client')"
      >
        <c-image
          :src="require('@/assets/icon-plus.svg')"
          alt="icon"
          display="inline-block"
          mr="10px"
        />
        Tambah Klien
      </c-button>
    </c-flex>

    <c-box
      mt="30px"
      w="100%"
      border="1px solid #f2f2f2"
      box-shadow="4px 4px 50px 5px #0000000D"
      rounded="lg"
      overflow="hidden"
    >
      <c-flex
        align-items="center"
        px="20px"
        h="75px"
      >
        <c-text flex="1">
          Daftar Klien
        </c-text>
        <c-stack
          is-inline
          spacing="10px"
        >
          <c-button
            v-if="role === 'super_admin'"
            h="40px"
            rounded="full"
            color="gray.700"
            variant="outline"
            variant-color="gray"
            font-size="14px"
            font-weight="400"
            type="button"
            as="a"
            :href="exportUrl"
            download="clients.xls"
          >
            <c-image
              :src="require('@/assets/icon-file-export.svg')"
              alt="icon"
              display="inline-block"
              mr="10px"
            />
            Export
          </c-button>
          <ManagementClientFilterBtn
            :filters="filters"
            @change="status = $event"
          />
        </c-stack>

        <c-box
          border-left="1px solid #f2f2f2"
          h="70%"
          w="2px"
          mx="20px"
        />
        <c-input-group
          bg="#F2F2F2"
          rounded="md"
        >
          <c-input-left-addon
            bg="none"
            border="none"
          >
            <c-image :src="require('@/assets/ic-search.svg')" />
          </c-input-left-addon>
          <c-input
            v-model="search"
            bg="none"
            border="none"
            size="sm"
            px="0"
            placeholder="Pencarian"
            :_placeholder="{
              color: '#555',
              opacity: 1,
            }"
          />
        </c-input-group>
      </c-flex>

      <ManagementClientTable
        :clients="clients"
        :rows="rows"
        :page="page"
        @detail="$emit('detail', $event)"
      />

      <c-flex
        h="85px"
        align-items="center"
        px="40px"
      >
        <c-flex
          flex="1"
          align-items="center"
        >
          <c-text
            color="#888"
            font-weight="400"
            font-size="14px"
          >
            Show :
          </c-text>
          <c-input-group>
            <c-select
              v-model="rows"
              v-chakra
              appearance="none"
              d="inline-block"
              rounded="md"
              ml="10px"
            >
              <option value="10">
                10 baris
              </option>
              <option value="30">
                30 baris
              </option>
              <option value="50">
                50 baris
              </option>
            </c-select>
          </c-input-group>
        </c-flex>

        <c-flex justify-content="flex-end">
          <c-flex
            justify-content="center"
            align="center"
          >
            <c-button
              type="button"
              p="0"
              mr="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="page === 1"
              as="router-link"
              :to="{ query: { ...$route.query, page: page - 1 } }"
            >
              <c-icon
                name="chevron-left"
                size="24px"
              />
            </c-button>

            <c-button
              v-for="(p, index) in pages"
              :key="index"
              v-chakra="{
                ':hover': {
                  bg: 'rgba(0, 140, 129, 0.3)',
                  color: 'primary.400',
                },
              }"
              p="0"
              m="0"
              w="32px"
              h="32px"
              min-w="32px"
              mx="4px"
              border-radius="30px"
              background-color="rgba(0, 140, 129, 0.3)"
              color="primary.400"
              :bg="p === page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent']"
              :is-disabled="isNaN(+p)"
              as="router-link"
              :to="{ name: $route.name, query: { ...$route.query, page: p } }"
            >
              {{ p }}
            </c-button>

            <c-button
              p="0"
              ml="16px"
              w="32px"
              h="32px"
              min-w="32px"
              variant="outline"
              border-radius="30px"
              background-color="transparent"
              :is-disabled="pages.length === page"
              as="router-link"
              :to="{ query: { ...$route.query, page: page + 1 } }"
            >
              <c-icon
                name="chevron-right"
                size="24px"
              />
            </c-button>
          </c-flex>
        </c-flex>
      </c-flex>
    </c-box>
  </c-flex>
</template>

<script>
import ManagementClientFilterBtn from '@/components/managements/clients/filter-button'
import { calculatePaginations } from '@/utils/calculate-paginations'
import ManagementClientTable from '@/components/managements/clients/table'

export default {
  name: 'ManagementClientsIndex',
  components: { ManagementClientTable, ManagementClientFilterBtn },
  props: ['title', 'filters', 'clients', 'exportUrl', 'role', 'totalData'],
  computed: {
    page: {
      get() {
        return +(this.$route.query.page ?? 1)
      },
      set(v) {
        if (+this.$route.query.page === v) return
        this.$router.replace({
          query: { ...this.$route.query, page: v },
        })
      },
    },
    search: {
      get() {
        return this.$route.query.q
      },
      set(v) {
        if (this.$route.query.q === v) return
        this.$router.replace({ query: { ...this.$route.query, q: v } })
      },
    },
    rows: {
      get() {
        return this.$route.query.rows ?? '10'
      },
      set(v) {
        if (this.$route.query.rows === v) return
        this.$router.replace({
          query: {
            ...this.$router.query,
            rows: v,
          },
        })
      },
    },
    status: {
      get() {
        return this.$route.query.status
      },
      set(v) {
        if (this.$route.query.status === v) return
        this.$router.replace({ query: { ...this.$route.query, status: v } })
      },
    },
    pages() {
      return calculatePaginations({
        perPage: +this.rows,
        totalData: this.totalData,
        currentPage: this.page,
      })
    },
  },
}
</script>
