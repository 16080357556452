var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "100%"
    }
  }, [_c('thead', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "bg": "#008C8137",
      "color": "primary.400",
      "text-align": "left",
      "h": "40px",
      "text-transform": "uppercase",
      "font-weight": "700",
      "font-size": "12px"
    }
  }, [_c('tr', [_c('th', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "pl": "20px"
    }
  }, [_vm._v(" No ")]), _c('th', [_vm._v("Foto Profil")]), _c('th', [_vm._v("Nama")]), _c('th', [_vm._v("Nomor HP")]), _c('th', [_vm._v("Email")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Action")])])]), _vm.clients.length === 0 ? _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": "7"
    }
  }, [_c('c-flex', {
    attrs: {
      "py": "80px",
      "px": "20px",
      "justify-content": "center"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty"
    }
  }), _c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-family": "Roboto",
      "font-size": "24px",
      "margin-top": "20px"
    }
  }, [_vm._v(" Klien tidak ditemukan ")])], 1)], 1)], 1)])]) : _c('tbody', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "text-align": "left",
      "font-size": "14px",
      "font-weight": "400"
    }
  }, _vm._l(_vm.clients, function (item, index) {
    return _c('tr', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      key: index,
      attrs: {
        "h": "90px",
        "border": index > 0 ? '1px solid #f2f2f2' : null
      }
    }, [_c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "pl": "20px"
      }
    }, [_vm._v(" " + _vm._s(+_vm.rows * (_vm.page - 1) + (index + 1)) + " ")]), _c('td', [_c('c-image', {
      attrs: {
        "h": "58px",
        "w": "58px",
        "object-fit": "cover",
        "rounded": "full",
        "src": _vm.getAvatarUrl(item.photoUrl)
      }
    })], 1), _c('td', [_vm._v(_vm._s(item.firstName) + " " + _vm._s(item.lastName))]), _c('td', [_vm._v(_vm._s(item.phone))]), _c('td', [_vm._v(_vm._s(item.email))]), item.programs.status === 'active' ? _c('td', [_c('c-box', {
      attrs: {
        "rounded": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "bg": "#0075E11A",
        "border": "1px solid #0075E1",
        "w": "fit-content",
        "py": "5px",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#0075E1",
        "font-size": "10px",
        "font-style": "normal",
        "font-weight": "400",
        "line-height": "15px"
      }
    }, [_vm._v(" Aktif ")])], 1)], 1) : item.programs.status === 'pending' ? _c('td', [_c('c-box', {
      attrs: {
        "rounded": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "bg": "rgba(244, 204, 70, 0.2)",
        "border": "1px solid #F4CC46",
        "w": "fit-content",
        "py": "5px",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#F4CC46",
        "font-size": "10px",
        "font-style": "normal",
        "font-weight": "400",
        "line-height": "15px"
      }
    }, [_vm._v(" Pending ")])], 1)], 1) : _c('td', [_c('c-box', {
      attrs: {
        "rounded": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "bg": "#EA43351A",
        "border": "1px solid #EA4335",
        "w": "fit-content",
        "py": "5px",
        "px": "10px"
      }
    }, [_c('c-text', {
      attrs: {
        "color": "#EA4335",
        "font-size": "10px",
        "font-style": "normal",
        "font-weight": "400",
        "line-height": "15px"
      }
    }, [_vm._v(" Tidak Aktif ")])], 1)], 1), _c('td', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }]
    }, [_c('c-button', {
      attrs: {
        "type": "button",
        "variant": "outline",
        "variant-color": "primary",
        "rounded": "full",
        "w": "74px",
        "h": "30px"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('detail', item.id);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "12px",
        "font-weight": "700",
        "line-height": "18px"
      }
    }, [_vm._v(" Lihat ")])], 1)], 1)]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }