var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-dir": "column",
      "w": "100%"
    }
  }, [_c('c-text', [_vm._v("Manajemen Klien")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "my": "10px"
    }
  }), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "2xl"
    }
  }, [_vm._v(" Manajemen Klien ")]), _vm.role === 'super_admin' ? _c('c-button', {
    attrs: {
      "h": "auto",
      "py": "12px",
      "px": "28px",
      "color": "#927102",
      "border-radius": "24px",
      "border-color": "transparent",
      "bg": "secondary.800",
      "variant-color": "inherit",
      "font-size": "16px",
      "line-height": "24px",
      "variant": "solid"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('add-client');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-plus.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Tambah Klien ")], 1) : _vm._e()], 1), _c('c-box', {
    attrs: {
      "mt": "30px",
      "w": "100%",
      "border": "1px solid #f2f2f2",
      "box-shadow": "4px 4px 50px 5px #0000000D",
      "rounded": "lg",
      "overflow": "hidden"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "px": "20px",
      "h": "75px"
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1"
    }
  }, [_vm._v(" Daftar Klien ")]), _c('c-stack', {
    attrs: {
      "is-inline": "",
      "spacing": "10px"
    }
  }, [_vm.role === 'super_admin' ? _c('c-button', {
    attrs: {
      "h": "40px",
      "rounded": "full",
      "color": "gray.700",
      "variant": "outline",
      "variant-color": "gray",
      "font-size": "14px",
      "font-weight": "400",
      "type": "button",
      "as": "a",
      "href": _vm.exportUrl,
      "download": "clients.xls"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-file-export.svg'),
      "alt": "icon",
      "display": "inline-block",
      "mr": "10px"
    }
  }), _vm._v(" Export ")], 1) : _vm._e(), _c('ManagementClientFilterBtn', {
    attrs: {
      "filters": _vm.filters
    },
    on: {
      "change": function change($event) {
        _vm.status = $event;
      }
    }
  })], 1), _c('c-box', {
    attrs: {
      "border-left": "1px solid #f2f2f2",
      "h": "70%",
      "w": "2px",
      "mx": "20px"
    }
  }), _c('c-input-group', {
    attrs: {
      "bg": "#F2F2F2",
      "rounded": "md"
    }
  }, [_c('c-input-left-addon', {
    attrs: {
      "bg": "none",
      "border": "none"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg')
    }
  })], 1), _c('c-input', {
    attrs: {
      "bg": "none",
      "border": "none",
      "size": "sm",
      "px": "0",
      "placeholder": "Pencarian",
      "_placeholder": {
        color: '#555',
        opacity: 1
      }
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('ManagementClientTable', {
    attrs: {
      "clients": _vm.clients,
      "rows": _vm.rows,
      "page": _vm.page
    },
    on: {
      "detail": function detail($event) {
        return _vm.$emit('detail', $event);
      }
    }
  }), _c('c-flex', {
    attrs: {
      "h": "85px",
      "align-items": "center",
      "px": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex": "1",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "#888",
      "font-weight": "400",
      "font-size": "14px"
    }
  }, [_vm._v(" Show : ")]), _c('c-input-group', [_c('c-select', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "appearance": "none",
      "d": "inline-block",
      "rounded": "md",
      "ml": "10px"
    },
    model: {
      value: _vm.rows,
      callback: function callback($$v) {
        _vm.rows = $$v;
      },
      expression: "rows"
    }
  }, [_c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 baris ")]), _c('option', {
    attrs: {
      "value": "30"
    }
  }, [_vm._v(" 30 baris ")]), _c('option', {
    attrs: {
      "value": "50"
    }
  }, [_vm._v(" 50 baris ")])])], 1)], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "type": "button",
      "p": "0",
      "mr": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.page === 1,
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          page: _vm.page - 1
        })
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (p, index) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n              ':hover': {\n                bg: 'rgba(0, 140, 129, 0.3)',\n                color: 'primary.400',\n              },\n            }"
      }],
      key: index,
      attrs: {
        "p": "0",
        "m": "0",
        "w": "32px",
        "h": "32px",
        "min-w": "32px",
        "mx": "4px",
        "border-radius": "30px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": p === _vm.page ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "is-disabled": isNaN(+p),
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: p
          })
        }
      }
    }, [_vm._v(" " + _vm._s(p) + " ")]);
  }), _c('c-button', {
    attrs: {
      "p": "0",
      "ml": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page,
      "as": "router-link",
      "to": {
        query: Object.assign({}, _vm.$route.query, {
          page: _vm.page + 1
        })
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }